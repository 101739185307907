<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12" md="2" sm="2" justify-center>
        <v-img src="~@/assets/logo.png" :width="240"></v-img>
      </v-col>
      <v-col cols="12" md="2" sm="2" class="text-h2 pa-2 d-flex">
        <p>Sisfama</p>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <menu-grid :items="items"></menu-grid>
  </v-container>
</template>

<script>
export default {
  name: "Home",
  components: {
    MenuGrid: () => import("@/components/base/MenuGrid"),
  },
  data: () => ({
    permissions: [],
    items: [
      {
        title: "DTEs",
        ico: "file-invoice",
        to: { name: "HomeDtes" },
        permission: "_view_dte",
      },
      {
        title: "Reportes",
        ico: "file-excel",
        to: { name: "HomeReports" },
        permission: "_view_reports",
      },
      {
        title: "Cuentas",
        ico: "money-check-alt",
        to: { name: "accounts-movement-all" },
        permission: "_view_accounts_movement",
      },
      {
        title: "Contabilidad",
        ico: "calculator",
        to: { name: "accounting-register-buy-home" },
        permission: "_view_accountings_register_buy",
      },
      {
        title: "Compras",
        ico: "cart-arrow-down",
        to: { name: "voucher-home" },
        permission: "_view_sale_orders",
      },
      {
        title: "Flota",
        ico: "truck-moving",
        to: { name: "fleet-home" },
        permission: "_view_fleets",
      },
      {
        title: "Proyectos",
        ico: "building",
        to: { name: "project-list-all" },
        permission: "_view_project",
      },
      {
        title: "Transacciones Internas",
        ico: "money-check",
        to: { name: "dealing-list-all" },
        permission: "_view_internalaccount",
      },
    ],
  }),
};
</script>
